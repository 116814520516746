import { memo, useEffect, useRef, useState } from 'react'

import { useToast } from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios, { AxiosRequestConfig } from 'axios'
import * as yup from 'yup'

import { Form } from '../../components/Form/Form'
import { Input } from '../../components/Form/Input'
import { FormContainer } from '../../components/FormContainer'
import { api } from '../../services/api'
import { formatErrorMessage } from '../../utils/formatErrorMessage'
import { formatYupError } from '../../utils/formatYupError'
import { masks } from '../../utils/masks'
import { updateFormSchema } from './validationSchema'

type FormData = {
  email: string
  cpfCnpj: string
  birthdate: string
}

type UseDataResponse = {
  data?: FormData
  refetch: () => Promise<void>
}

export async function getData(config?: AxiosRequestConfig): Promise<FormData> {
  const { data } = await api.get(`/lawyer/settings/profile`, {
    ...config
  })

  return {
    ...data,

    birthdate: data?.birthdate.split('T')[0]
  }
}

export function useData(): UseDataResponse {
  const [data, setData] = useState<FormData | undefined>()

  useEffect(() => {
    getData().then(setData)
  }, [])

  async function refetch(): Promise<void> {
    const data = await getData()
    setData(data)
  }

  return { data, refetch }
}

export function SelfUpdateRaw(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const formRef = useRef<FormHandles>(null)
  const { data, refetch } = useData()
  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setLoading(true)
    try {
      const res = await updateFormSchema.validate(data, { abortEarly: false })
      await api.put(`/lawyer/settings/profile`, res)
      toast({
        status: 'success',
        title: 'Sucesso!',
        description: 'Atualizado com sucesso!.'
      })
      refetch()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({
          status: 'error',
          description: formatErrorMessage(error)
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{
        email: data?.email,
        cpfCnpj: data?.cpfCnpj && masks.cpfCnpj(data.cpfCnpj),
        birthdate: data?.birthdate
      }}
    >
      <FormContainer
        headerText="Dados Cadastrais"
        buttonText="Salvar Alterações"
        loading={loading}
      >
        <Input
          name="email"
          label="Email (obrigatório)"
          placeholder="Informe seu email"
          mb="4"
        />
        <Input
          name="cpfCnpj"
          label="CPF/CNPJ(obrigatório)"
          placeholder="Informe seu CPF/CNPJ"
          mask="cpfCnpj"
          mb="4"
        />
        <Input
          name="birthdate"
          label="Data de Nascimento (obrigatório)"
          placeholder="Informe sua data de nascimento"
          type="date"
          mb="4"
        />
      </FormContainer>
    </Form>
  )
}

export const SelfUpdate = memo(SelfUpdateRaw)
