import { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  Center,
  Divider,
  Grid,
  Heading,
  Img,
  useToast,
  useBreakpointValue,
  Box
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import * as yup from 'yup'

import { Form } from '../components/Form/Form'
import { Input } from '../components/Form/Input'
import { useQuery } from '../hooks/useQuery'
import { api } from '../services/api'
import { formatErrorMessage } from '../utils/formatErrorMessage'
import { formatYupError } from '../utils/formatYupError'

type FormData = {
  password: string
  confirmPassword: string
}

const resetFormSchema = yup.object({
  password: yup.string().required('Senha é obrigatório.'),
  confirmPassword: yup
    .string()
    .is([yup.ref('password')], 'As senhas devem ser iguais.')
    .required('Confirmação da senha é obrigatório.')
})

export function Reset(): JSX.Element {
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const token = useQuery().get('token')
  const isWideVersion = useBreakpointValue({ base: false, md: true })
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setIsLoading(true)
    try {
      await resetFormSchema.validate(data, { abortEarly: false })
      await api.post(`/password/${token}`, data)
      toast({ status: 'success', description: 'Senha alterada com sucesso!' })
      history.push('/signin')
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({
          description: formatErrorMessage(error),
          status: 'error'
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid
      w="full"
      h="100vh"
      gridTemplateColumns={isWideVersion ? '1fr 1fr' : '1fr'}
      gridTemplateRows={isWideVersion ? '1fr' : '185px 1fr'}
    >
      <Box pos="relative">
        <Img
          src="/lawy.webp"
          alt="advogados"
          w="full"
          h={isWideVersion ? '100%' : '185px'}
          objectFit="cover"
        />

        <Img
          src="/logo.svg"
          alt="uniadvogados"
          pos="absolute"
          w="100%"
          maxW={['200px', '300px', '400px']}
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        />
      </Box>
      <Center p="4">
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          w="full"
          maxW="400"
          pt="5"
          pb="8"
          px="9"
          boxShadow="md"
          borderRadius="4"
        >
          <Heading fontSize="xl">Redefinir senha</Heading>
          <Divider my="4" />
          <Input
            name="password"
            type="password"
            label="Nova senha"
            placeholder="Informe seu nova senha"
            mb="3"
          />
          <Input
            name="confirmPassword"
            type="password"
            label="Confirmar senha"
            placeholder="Confirme sua nova senha"
          />

          <Button
            type="submit"
            colorScheme="yellow"
            w="full"
            h="48px"
            mt="7"
            fontSize="lg"
            isLoading={isLoading}
          >
            Atualizar senha
          </Button>
        </Form>
      </Center>
    </Grid>
  )
}
