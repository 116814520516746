/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { Link as ReactLink, useHistory } from 'react-router-dom'

import {
  Flex,
  Text,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  useToast,
  Link,
  VStack
} from '@chakra-ui/react'

import { PageTemplate } from '../components/PageTemplate'
import { PlanCard, PlanDescription } from '../components/PlanCard'
import { useAuth } from '../hooks/useAuth'
import { Plan as PlanType, usePlans } from '../hooks/usePlans'
import { useQueryParams } from '../hooks/useQueryParams'
import { api } from '../services/api'

export function Plans(): JSX.Element {
  const { user, updateUser } = useAuth()
  const { data } = usePlans()
  const query = useQueryParams()
  const history = useHistory()
  const [modalPlan, setModalPlan] = useState<PlanType | null>(null)

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  async function handleSelectPlanWithCard(): Promise<void> {
    if (modalPlan?.slug === 'free') {
      await api.patch(`/lawyer/plans/unsubscribe`)
      updateUser({
        plan: modalPlan?.slug
      })
      toast({
        description: 'Plano atualizado com sucesso!',
        status: 'success'
      })
      history.push('/home')
    } else {
      await api.put(`/lawyer/plans/update/${modalPlan?.slug}`)
      updateUser({
        plan: modalPlan?.slug
      })
      toast({ description: 'Plano atualizado com sucesso!', status: 'success' })
      history.push('/home')
    }
  }

  async function handleClickPlan(plan: PlanType): Promise<void> {
    if (plan.slug === user?.plan) {
      // if plan is equal to user plan
      toast({
        description: `Seu plano já é o ${plan.name}`,
        status: 'warning'
      })
    } else if (user?.hasCard || plan.slug === 'free') {
      // if user has card
      setModalPlan(plan)
    } else {
      // if user doesn't have plan
      history.push(`/plans/${plan.slug}`)
    }
  }

  return (
    <>
      <PageTemplate>
        <Box
          w="full"
          maxW={1200}
          p="4"
          pb="8"
          pt={['4', '4', '60px']}
          mx="auto"
        >
          <Text fontSize="lg">Selecione seu plano para continuar...</Text>
          <Flex
            mt="7"
            w="full"
            h="750px"
            alignContent="stretch"
            justify="center"
            gridGap="4"
            flexWrap="wrap"
          >
            <PlanCard plan={data?.plans[0]} onClick={handleClickPlan}>
              <VStack gridGap="2">
                <PlanDescription
                  title="Resultado nas Buscas"
                  text="Abaixo do Plano Básico e do Plano Premium"
                />
                <PlanDescription title="Áreas de Atuação" text="1 área" />
                <PlanDescription title="Localidades" text="1 localidade" />
                <PlanDescription title="Whatsapp" text="Exibição do número" />
                <PlanDescription
                  title="Perfil"
                  text="Galeria de fotos indisponível"
                />
                <PlanDescription
                  title="Destaque no Perfil"
                  text="Indisponível"
                />
              </VStack>
            </PlanCard>
            <PlanCard plan={data?.plans[1]} onClick={handleClickPlan}>
              <VStack gridGap="2">
                <PlanDescription
                  title="Resultado nas Buscas"
                  text="Acima do Plano Gratuito e abaixo do Plano Premium"
                />
                <PlanDescription title="Áreas de Atuação" text="2 áreas" />
                <PlanDescription title="Localidades" text="4 localidades" />
                <PlanDescription title="Whatsapp" text="Exibição do número" />
                <PlanDescription title="Perfil" text="Ilimitado" />
                <PlanDescription
                  title="Destaque no Perfil"
                  text="Tag do Plano"
                />
              </VStack>
            </PlanCard>
            <PlanCard plan={data?.plans[2]} onClick={handleClickPlan}>
              <VStack gridGap="2">
                <PlanDescription
                  title="Resultado nas Buscas"
                  text="Acima do Plano Gratuito e do Plano Básico"
                />
                <PlanDescription title="Áreas de Atuação" text="Ilimitado" />
                <PlanDescription title="Localidades" text="Ilimitado" />
                <PlanDescription
                  title="Whatsapp"
                  text="Botão para Contato direto"
                />
                <PlanDescription title="Perfil" text="Ilimitado" />
                <PlanDescription
                  title="Destaque no Perfil"
                  text="Destaque na Foto de Perfil e Tag do Plano"
                />
              </VStack>
            </PlanCard>
          </Flex>
          {!!query.get('firstAccess') && (
            <Link as={ReactLink} to="/home" mt="4" d="inline-block">
              Escolher depois
            </Link>
          )}
        </Box>
      </PageTemplate>

      <Modal isOpen={!!modalPlan} onClose={() => setModalPlan(null)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Deseja selecionar o ${modalPlan?.name}?`}</ModalHeader>
          <ModalBody>
            {`Ao confirmar você irá assinar o ${modalPlan?.name} e o plano for pago o valor será cobrado do cartão cadastrado.`}
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              variant="outline"
              colorScheme="gray"
              onClick={() => setModalPlan(null)}
            >
              Cancelar
            </Button>
            <Button colorScheme="yellow" onClick={handleSelectPlanWithCard}>
              Assinar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
