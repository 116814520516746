import {
  createContext,
  ReactNode,
  useState,
  useCallback,
  useEffect
} from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from '@chakra-ui/toast'
import Axios, { AxiosError } from 'axios'

import { api } from '../services/api'
import { queryClient } from '../services/queryClient'
import { formatErrorMessage } from '../utils/formatErrorMessage'

type Plan = 'free' | 'silver' | 'gold'

type AuthContextProviderProps = {
  children: ReactNode
}

type User = {
  id: string
  fullName: string
  token: string
  plan: Plan
  active: boolean
  hasCard: boolean
}

type SignInData = {
  email: string
  password: string
}

type RegisterUserData = {
  name: string
  lastName: string
  email: string
  cpfCnpj: string
  whatsapp: string
  oab: string
  birthdate: string
  password: string
  confirmPassword: string
}

export type AuthContextData = {
  user: User | null
  isLogged: boolean
  isAuthenticated: boolean
  loading: boolean
  confirmPin: (pin: string) => Promise<void>
  registerUser: (data: RegisterUserData) => Promise<void>
  signIn: (signInData: SignInData) => Promise<void>
  signOut: (redirect?: boolean) => void
  updateUser: (user: Partial<User> | null) => void
}

type SignOut = (redirect?: boolean) => void

export const AuthContext = createContext({} as AuthContextData)

export function signOut(redirect = true): void {
  delete api.defaults.headers.Authorization
  localStorage.removeItem('@lawy-advogados:token')
  queryClient.clear()
  if (redirect) window.location.href = '/signin'
}

export function AuthContextProvider({
  children
}: AuthContextProviderProps): JSX.Element {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<User | null>(null)
  const history = useHistory()
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  const isAuthenticated = !!user?.active
  const isLogged = !!user

  const clientSignOut: SignOut = useCallback((redirect = true) => {
    delete api.defaults.headers.Authorization
    setUser(null)
    signOut(false)
    if (redirect) history.push('/signin')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('@lawy-advogados:token')
    api.defaults.headers.Authorization = `Bearer ${token}`
    api
      .get<User>('/lawyer/me')
      .then(({ data }) => setUser(data))
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) clientSignOut(false)
      })
      .finally(() => setLoading(false))
  }, [clientSignOut])

  const signIn = useCallback(async ({ email, password }: SignInData) => {
    try {
      const { data } = await api.post<User>('/lawyer/auth', { email, password })
      api.defaults.headers.Authorization = `Bearer ${data.token}`
      localStorage.setItem('@lawy-advogados:token', data.token)
      setUser(data)
      history.push('/home')
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toast({
          description: formatErrorMessage(error),
          status: 'error'
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const confirmPin = useCallback(
    async (pin: string): Promise<void> => {
      await api.post<User>('/lawyer/account', { code: pin })

      setUser(user ? { ...user, active: true } : null)
      history.push('/plans?firstAccess=true')
    },
    [history, user]
  )

  const registerUser = useCallback(
    async (data: RegisterUserData) => {
      const res = await api.post<User>('/lawyer/register', data)

      api.defaults.headers.Authorization = `Bearer ${res.data.token}`
      localStorage.setItem('@lawy-advogados:token', res.data.token)
      setUser(res.data)
      history.push('/pin')
    },
    [history]
  )

  function updateUser(newUser: Partial<User> | null): void {
    if (newUser === null) {
      setUser(null)
    } else {
      setUser(oldUser => (oldUser === null ? null : { ...oldUser, ...newUser }))
    }
  }

  return (
    <AuthContext.Provider
      value={{
        updateUser,
        isAuthenticated,
        isLogged,
        user,
        loading,
        signIn,
        confirmPin,
        registerUser,
        signOut: clientSignOut
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
