import { useRef, useState } from 'react'
import { Link as ReactLink, useHistory } from 'react-router-dom'

import {
  Button,
  Center,
  Divider,
  Grid,
  Heading,
  Img,
  Link,
  useBreakpointValue,
  useToast,
  Box
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import * as yup from 'yup'

import { Form } from '../components/Form/Form'
import { Input } from '../components/Form/Input'
import { api } from '../services/api'
import { formatYupError } from '../utils/formatYupError'

type FormData = {
  email: string
}

const forgotFormSchema = yup.object({
  email: yup.string().email('E-mail Inválido').required('E-mail é obrigatório.')
})

export function Forgot(): JSX.Element {
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const isWideVersion = useBreakpointValue({ base: false, md: true })
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setIsLoading(true)
    try {
      await forgotFormSchema.validate(data, { abortEarly: false })
      await api.post('/lawyer/password', data)
      toast({
        status: 'success',
        title: 'Sucesso!',
        description: 'Email enviado com sucesso!'
      })
      history.push('/signin')
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({
          status: 'error',
          description: error.response?.data?.message
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid
      w="full"
      h="100vh"
      gridTemplateColumns={isWideVersion ? '1fr 1fr' : '1fr'}
      gridTemplateRows={isWideVersion ? '1fr' : '185px 1fr'}
    >
      <Box pos="relative">
        <Img
          src="/lawy.webp"
          alt="advogados"
          w="full"
          h={isWideVersion ? '100%' : '185px'}
          objectFit="cover"
        />

        <Img
          src="/logo.svg"
          alt="uniadvogados"
          pos="absolute"
          w="100%"
          maxW={['200px', '300px', '400px']}
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        />
      </Box>
      <Center p="4">
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          w="full"
          maxW="400"
          pt="5"
          pb="7"
          px="9"
          boxShadow="md"
          borderRadius="4"
          d="flex"
          flexDirection="column"
        >
          <Heading as="h1" fontSize="xl">
            Esqueceu sua senha?
          </Heading>
          <Divider my="4" />
          <Input name="email" label="E-mail" placeholder="Informe seu e-mail" />

          <Button
            type="submit"
            colorScheme="yellow"
            w="full"
            h="48px"
            my="6"
            fontSize="lg"
            isLoading={isLoading}
          >
            Recuperar senha
          </Button>

          <Link
            as={ReactLink}
            to="/signin"
            d="block"
            textDecoration="underline"
          >
            Voltar
          </Link>
        </Form>
      </Center>
    </Grid>
  )
}
