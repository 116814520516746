import { ChangeEvent, useEffect, useRef } from 'react'
import { IoAlertCircle, IoAlertCircleOutline } from 'react-icons/io5'

import {
  InputProps as ChakraInputProps,
  Input as ChakraInput,
  theme,
  InputGroup,
  IconButton,
  Icon,
  InputRightElement,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { useField } from '@unform/core'

import { masks } from '../../utils/masks'
import { Tooltip } from '../Tooltip'

type Mask =
  | 'cpf'
  | 'cnpj'
  | 'phone'
  | 'cpfCnpj'
  | 'oab'
  | 'cep'
  | 'creditCard'
  | 'expiryDate'
  | 'number'

type InputProps = ChakraInputProps & {
  name: string
  label?: string
  mask?: Mask
  info?: string
}

export function Input({
  name,
  label,
  mask,
  info,
  ...rest
}: InputProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null)
  const { defaultValue, error, fieldName, registerField, clearError } =
    useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref: HTMLInputElement, value: string) => {
        if (mask) {
          ref.value = masks[mask](value)
        } else {
          ref.value = value
        }
      }
    })
  }, [fieldName, mask, registerField])

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    if (mask) {
      const { value } = event.target
      if (inputRef.current) {
        inputRef.current.value = masks[mask](value)
      }
    }
  }

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel mb="4" d="flex" alignItems="center" gridGap="2">
          {label}
          {info && (
            <Tooltip
              label={info}
              placement="right"
              aria-label={`${label} info`}
            >
              <button type="button" aria-label="Abrir informação extra">
                <Icon as={IoAlertCircle} />
              </button>
            </Tooltip>
          )}
        </FormLabel>
      )}
      <InputGroup>
        <ChakraInput
          ref={inputRef}
          defaultValue={mask ? masks[mask](defaultValue ?? '') : defaultValue}
          colorScheme="yellow"
          onFocus={clearError}
          onChange={handleChange}
          _focus={{
            boxShadow: `0 0 0 1px ${theme.colors.yellow['300']}`,
            borderColor: 'yellow.300'
          }}
          {...rest}
        />
        {!!error && (
          <InputRightElement>
            <Tooltip
              label={error}
              placement="top-end"
              aria-label="Error tooltip"
            >
              <IconButton
                d="flex"
                icon={<Icon as={IoAlertCircleOutline} />}
                fontSize="22"
                variant="unstyled"
                aria-label="Abrir mensagem de erro"
                color="red.500"
              />
            </Tooltip>
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  )
}
