import { ChangeEvent, useRef, useState } from 'react'
import { IoCamera } from 'react-icons/io5'

import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input as ChakraInput,
  Image,
  useToast
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import { updatedDiff } from 'deep-object-diff'
import * as yup from 'yup'

import { ContentWrapper } from '../components/ContentWrapper'
import { Form } from '../components/Form/Form'
import { Input } from '../components/Form/Input'
import { Select } from '../components/Form/Select'
import { Textarea } from '../components/Form/Textarea'
import { AcademicFormation } from '../components/Me/AcademicFormation'
import { Fields } from '../components/Me/Fields'
import { Offices } from '../components/Me/Offices'
import { OnlineService } from '../components/Me/OnlineService'
import { Pictures } from '../components/Me/Pictures'
import { PresentialService } from '../components/Me/PresentialService'
import { PageTemplate } from '../components/PageTemplate'
import { useMe } from '../hooks/useMe'
import { api } from '../services/api'
import { formatErrorMessage } from '../utils/formatErrorMessage'
import { formatYupError } from '../utils/formatYupError'
import { transformEmptyStringToNull } from '../utils/transformEmptyStringToNull'
import { uploadImage } from '../utils/uploadImage'
import { validateCellphone } from '../utils/validations'
type FormData = {
  id: string
  photo: string
  title: 'dr.' | 'me.' | 'phd' | 'prof.' | 'esp.' | '' | ''
  name: string
  lastName: string
  gender: 'male' | 'female' | ''
  oab: string
  publicEmail: string
  alternativeMobile1: string
  alternativeMobile2: string
  whatsapp: string
  whatsappMessage: string
  facebook: string
  instagram: string
  linkedin: string
  telegram: string
  tiktok: string
  twitter: string
  website: string
  youtube: string
  information: string
}

const meFormSchema = yup.object({
  photo: yup.string().notRequired(),
  title: yup
    .string()
    .is(['Dr.', 'Esp.', 'Me.', 'PhD', 'Prof.', ''], 'Título é obrigatório.'),
  name: yup.string().required('O nome é obrigatório.'),
  lastName: yup.string().notRequired(),
  gender: yup.string().is(['male', 'female', ''], 'Gênero é obrigatório.'),
  oab: yup.string().required('OAB é obrigatória.'),
  whatsapp: yup
    .string()
    .transform(value => value.replace(/\D/g, ''))
    .test('Cellphone error', 'Digite um telefone válido', validateCellphone)
    .required('Whatsapp é obrigatório'),
  whatsappMessage: yup.string().notRequired(),
  publicEmail: yup.string().email('E-mail inválido').notRequired(),
  alternativeMobile1: yup
    .string()
    .transform(value => value.replace(/\D/g, ''))
    .test('Cellphone error', 'Digite um telefone válido', value =>
      value?.length === 0 ? true : validateCellphone(value)
    )

    .notRequired(),
  alternativeMobile2: yup
    .string()
    .transform(value => value.replace(/\D/g, ''))
    .test('Cellphone error', 'Digite um telefone válido', value =>
      value?.length === 0 ? true : validateCellphone(value)
    )
    .notRequired(),
  website: yup.string().notRequired(),
  youtube: yup.string().notRequired(),
  linkedin: yup.string().notRequired(),
  facebook: yup.string().notRequired(),
  instagram: yup.string().notRequired(),
  twitter: yup.string().notRequired(),
  tiktok: yup.string().notRequired(),
  telegram: yup.string().notRequired(),
  information: yup
    .string()
    .max(500, 'O sobre deve ter menos de 500 caracteres.')
    .notRequired()
})

export function Me(): JSX.Element {
  const formRef = useRef<FormHandles>(null)
  const { data, updateData, refetch } = useMe()
  const inputRef = useRef<HTMLInputElement>(null)
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })
  const accept = 'image/png, image/jpeg, image/jpg'
  const [photo, setPhoto] = useState<string | undefined>()

  const handleSubmit: SubmitHandler<FormData> = async formData => {
    try {
      const res = await meFormSchema.validate(
        { ...formData, ...(photo ? { photo } : {}) },
        { abortEarly: false }
      )
      if (data) {
        const cleanedData = transformEmptyStringToNull(
          updatedDiff(data.me, transformEmptyStringToNull(res))
        )
        if (JSON.stringify(cleanedData) === '{}') return
        await api.put('/lawyer/profile', cleanedData)
        toast({
          description: 'Suas informações foram atualizadas com sucesso!',
          status: 'success'
        })
        refetch()
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({
          description: formatErrorMessage(error),
          status: 'error'
        })
      }
    }
  }

  async function submitImage(
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    try {
      const image = event.target.files?.[0]
      const { fileName, filePath } = await uploadImage(image)
      updateData(oldData =>
        oldData
          ? {
              me: {
                ...oldData.me,
                photo: filePath
              }
            }
          : oldData
      )
      setPhoto(fileName)
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        toast({
          status: 'error',
          description: error.message
        })
      }
      toast({
        status: 'error',
        description: 'Ocorreu um erro ao atualizar a foto de perfil'
      })
    }
  }

  return (
    <PageTemplate>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        w="100%"
        mt="60px"
        mb="30px"
        initialData={data?.me}
      >
        <Button
          colorScheme="yellow"
          d="block"
          type="submit"
          fontSize="lg"
          ml="auto"
          mb="40px"
        >
          Salvar alterações
        </Button>
        <Grid templateColumns={['1fr', 'fr', '1fr 2fr']} gap="8">
          <GridItem d="flex" flexDirection="column" gridGap="8">
            <Box boxShadow="md" borderRadius="8">
              <Box
                h="100px"
                bg="gray.700"
                top="0"
                left="0"
                borderTopRadius="8"
              />
              <Center mb="20">
                <Box w="148px" h="148px" pos="absolute">
                  <Image
                    src={data?.me.photo || '/profile.png'}
                    alt="Foto de perfil"
                    w="148px"
                    h="148px"
                    borderRadius="50%"
                    objectFit="cover"
                  />
                  <ChakraInput
                    d="none"
                    type="file"
                    onChange={submitImage}
                    accept={accept}
                    ref={inputRef}
                  />
                  <IconButton
                    icon={<Icon as={IoCamera} color="white" />}
                    borderRadius="50%"
                    minW="8"
                    minH="8"
                    h="6"
                    bg="gray.700"
                    _hover={{ bg: 'gray.600' }}
                    pos="absolute"
                    bottom="2"
                    right="2"
                    fontSize="18"
                    aria-label="1233123"
                    onClick={() => inputRef.current?.click()}
                  />
                </Box>
              </Center>
              <Flex direction="column" gridGap="3" w="100%" px="4" pb="6">
                <Select
                  name="title"
                  label="Título"
                  isLoading={!data?.me}
                  options={[
                    { value: '', label: 'Sem título' },
                    { value: 'Dr.', label: 'Dr.' },
                    { value: 'PhD', label: 'PhD' },
                    { value: 'Prof.', label: 'Prof.' },
                    { value: 'Esp.', label: 'Esp.' },
                    { value: 'Me.', label: 'Me.' }
                  ]}
                />

                <Input
                  name="name"
                  label="Nome"
                  placeholder="Informe seu nome"
                />
                <Input
                  name="lastName"
                  label="Sobrenome"
                  placeholder="Informe seu sobrenome"
                />
                <Select
                  name="gender"
                  label="Sexo"
                  isLoading={!data?.me}
                  options={[
                    { value: 'male', label: 'Masculino' },
                    { value: 'female', label: 'Feminino' }
                  ]}
                  placeholder="Desejo não me identificar"
                />
                <Input
                  name="oab"
                  label="OAB"
                  mask="oab"
                  placeholder="Informe seu registro OAB"
                />
              </Flex>
            </Box>
            <ContentWrapper w="100%" title="Contato">
              <Flex gridGap="4" direction="column">
                <Input
                  name="publicEmail"
                  label="E-mail público"
                  placeholder="Informe seu e-mail para contato"
                />
                <Input
                  name="alternativeMobile1"
                  label="Telefone/Celular alternativo 1"
                  placeholder="Informe seu telefone/celular"
                  mask="phone"
                />
                <Input
                  name="alternativeMobile2"
                  label="Telefone/Celular alternativo 2"
                  placeholder="Informe seu telefone/celular"
                  mask="phone"
                />
              </Flex>
            </ContentWrapper>
            <ContentWrapper w="100%" title="Whatsapp">
              <Flex gridGap="4" direction="column">
                <Input
                  name="whatsapp"
                  label="Número do Whatsapp"
                  placeholder="99 99999-9999"
                  mask="phone"
                />
                <Textarea
                  name="whatsappMessage"
                  label="Mensagem de boas vindas"
                  placeholder="Escreva aqui sua mensagem de boas vindas"
                />
              </Flex>
            </ContentWrapper>
            <ContentWrapper w="100%" title="Redes Sociais">
              <Flex gridGap="4" direction="column">
                <Input
                  name="website"
                  label="Website"
                  placeholder="https://meusite.com.br"
                  type="url"
                />
                <Input
                  name="youtube"
                  label="Youtube"
                  placeholder="https://canal.youtube.com.br"
                  type="url"
                />
                <Input
                  name="linkedin"
                  label="Linkedin"
                  placeholder="https://linkedin/in/meunome.com.br"
                  type="url"
                />
                <Input
                  name="facebook"
                  label="Facebook"
                  placeholder="https://facebook.com/meunome"
                  type="url"
                />
                <Input
                  name="instagram"
                  label="Instagram"
                  placeholder="https://instagram.com/meunome"
                  type="url"
                />
                <Input
                  name="twitter"
                  label="Twitter"
                  placeholder="https://twitter.com/meunome"
                  type="url"
                />
                <Input
                  name="tiktok"
                  label="Tiktok"
                  placeholder="https://tiktok.com/meunome"
                  type="url"
                />
                <Input
                  name="telegram"
                  label="Telegram"
                  placeholder="https://telegram.com/meunome"
                  type="url"
                />
              </Flex>
            </ContentWrapper>
          </GridItem>
          <GridItem d="flex" flexDirection="column" gridGap="8">
            <ContentWrapper
              w="100%"
              title="Sobre"
              infoLabel="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
            >
              <Textarea name="information" />
            </ContentWrapper>
            <ContentWrapper
              w="full"
              title="Locais de atendimento"
              infoLabel="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
            >
              <PresentialService />
              <OnlineService />
            </ContentWrapper>
            <Offices />
            <Fields />
            <AcademicFormation />
            <Pictures />
          </GridItem>
        </Grid>
      </Form>
    </PageTemplate>
  )
}
