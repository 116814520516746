/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'

import {
  Center,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Img,
  PinInput,
  PinInputField,
  useBreakpointValue,
  useToast,
  Text,
  Button,
  Box
} from '@chakra-ui/react'
import axios from 'axios'

import { useAuth } from '../hooks/useAuth'
import { api } from '../services/api'
import { formatErrorMessage } from '../utils/formatErrorMessage'

export function Pin(): JSX.Element {
  const [value, setValue] = useState('')
  const [isLimited, setIsLimited] = useState(false)
  const isWideVersion = useBreakpointValue({ base: false, md: true })
  const { confirmPin, signOut } = useAuth()
  let debounce: NodeJS.Timeout
  let debounceResendCode: NodeJS.Timeout
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  async function handleResendCode(): Promise<void> {
    clearTimeout(debounceResendCode)
    debounceResendCode = setTimeout(async () => {
      try {
        await api.patch('/lawyer/re-send')
        setIsLimited(true)
        toast({
          status: 'success',
          title: 'E-mail enviado!',
          description: 'Reenviamos um e-mail com seu código'
        })
        setTimeout(() => {
          setIsLimited(false)
        }, 30000)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toast({
            status: 'error',
            description: formatErrorMessage(error)
          })
        }
      }
    }, 500)
  }

  function handleSubmit(pin: string): void {
    clearTimeout(debounce)
    debounce = setTimeout(async () => {
      if (pin.length !== 4) {
        return toast({
          description: 'Pin deve ter 4 dígitos.',
          status: 'warning'
        })
      }
      try {
        await confirmPin(pin)
      } catch (error) {
        setValue('')
        toast({
          description: 'O código pin está incorreto.',
          status: 'error'
        })
      }
    }, 750)
  }

  function handleChange(pin: string): void {
    setValue(pin)
    if (pin.length === 4) handleSubmit(pin)
  }
  return (
    <Grid
      w="full"
      h="100vh"
      gridTemplateColumns={isWideVersion ? '1fr 1fr' : '1fr'}
      gridTemplateRows={isWideVersion ? '1fr' : '185px 1fr'}
    >
      <Box pos="relative">
        <Img
          src="/lawy.webp"
          alt="advogados"
          w="full"
          h={isWideVersion ? '100%' : '185px'}
          objectFit="cover"
        />
        <Img
          src="/logo.svg"
          alt="uniadvogados"
          pos="absolute"
          w="100%"
          maxW={['200px', '300px', '400px']}
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        />
      </Box>
      <Center p="4" d="flex" flexDirection="column">
        <Flex
          w="full"
          maxW="400"
          pt="5"
          pb="7"
          px="9"
          borderRadius="4"
          boxShadow="md"
          align="center"
          direction="column"
        >
          <Heading fontSize="lg" w="full">
            Verificação de conta
          </Heading>
          <Divider mt="4" mb="2" />
          <Text>Informe abaixo o código que enviamos para o seu e-mail.</Text>
          <HStack spacing="1rem" mt="4" mb="8">
            <PinInput
              value={value}
              placeholder="✶"
              onComplete={handleSubmit}
              onChange={handleChange}
            >
              <PinInputField
                w="52px"
                h="52px"
                _placeholder={{ color: 'gray.200' }}
              />
              <PinInputField
                w="52px"
                h="52px"
                _placeholder={{ color: 'gray.200' }}
              />
              <PinInputField
                w="52px"
                h="52px"
                _placeholder={{ color: 'gray.200' }}
              />
              <PinInputField
                w="52px"
                h="52px"
                _placeholder={{ color: 'gray.200' }}
              />
            </PinInput>
          </HStack>
          <Text textAlign="center">
            Não recebeu nenhum código?
            <Button
              variant="link"
              onClick={handleResendCode}
              textDecoration="underline"
              disabled={isLimited}
            >
              Clique para reenviar
            </Button>
          </Text>
        </Flex>

        <Button
          variant="link"
          to="signin"
          d="inline-block"
          mt="4"
          fontWeight="normal"
          onClick={() => signOut()}
        >
          Voltar ao login
        </Button>
      </Center>
    </Grid>
  )
}
