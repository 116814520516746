import { useRef, useState } from 'react'
import { Link as ReactLink } from 'react-router-dom'

import {
  Box,
  Button,
  Center,
  Divider,
  Grid,
  Heading,
  Img,
  Link,
  Text,
  useToast,
  useBreakpointValue
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import * as yup from 'yup'

import { Checkbox } from '../components/Form/Checkbox'
import { Form } from '../components/Form/Form'
import { Input } from '../components/Form/Input'
import { useAuth } from '../hooks/useAuth'
import { api } from '../services/api'
import { formatErrorMessage } from '../utils/formatErrorMessage'
import { formatYupError } from '../utils/formatYupError'
import {
  validateCellphone,
  validateDate,
  validateOab
} from '../utils/validations'

type Screen = 'user' | 'lawyer'

type FormUserData = {
  name: string
  lastName: string
  email: string
}

type FormLawyerData = FormUserData & {
  cpfCnpj: string
  mobile: string
  oab: string
  birthdate: string
  password: string
  confirmPassword: string
}
const userFormSchema = yup.object({
  name: yup.string().required('Nome é obrigatório.'),
  lastName: yup.string().required('Sobrenome é obrigatório.'),
  email: yup
    .string()
    .email('E-mail Inválido.')
    .required('E-mail é obrigatório.')
})

const lawyerFormSchema = yup.object({
  name: yup.string().required('Nome é obrigatório.'),
  lastName: yup.string().required('Sobrenome é obrigatório.'),
  email: yup
    .string()
    .email('E-mail Inválido.')
    .required('E-mail é obrigatório.'),
  cpfCnpj: yup.string().required('Nome é obrigatório.'),
  whatsapp: yup
    .string()
    .test('is-valid-mobile', 'Telefone inválido.', validateCellphone)
    .required('Sobrenome é obrigatório.'),
  oab: yup
    .string()
    .test('Oab error', 'OAB é inválido', validateOab)
    .required('E-mail é obrigatório.'),
  birthdate: yup
    .string()
    .test('date', 'Data de nascimento inválida.', validateDate)
    .transform(value => {
      const [year, month, day] = value.split('-')
      return `${day}-${month}-${year}`
    })
    .required('Data de nascimento é obrigatória.'),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      'Senha deve ter 6, caracteres, letras maiúsculas, números e caracteres especiais (@$!%*#?&)'
    )
    .required('Senha é obrigatória.'),
  confirmPassword: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      'Senha deve ter 6, caracteres, letras maiúsculas, números e caracteres especiais (@$!%*#?&)'
    )
    .oneOf([yup.ref('password')], 'Senha precisa ser igual')
    .required('Confirmação da senha é obrigatória.'),
  terms: yup
    .boolean()
    .isTrue('Você deve aceitar os termos de uso.')
    .required('Você deve aceitar os termos de uso.')
})

export function SignUp(): JSX.Element {
  const formRef = useRef<FormHandles>(null)
  const isWideVersion = useBreakpointValue({ base: false, md: true })
  const { registerUser } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [screen, setScreen] = useState<Screen>('user')
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  async function handleSubmitLawyer(data: FormLawyerData): Promise<void> {
    setIsLoading(true)
    try {
      const res = await lawyerFormSchema.validate(data, { abortEarly: false })
      registerUser(res)
    } catch (error) {
      if (error instanceof yup.ValidationError)
        formRef.current?.setErrors(formatYupError(error))
      else if (axios.isAxiosError(error))
        toast({ status: 'error', description: formatErrorMessage(error) })
    } finally {
      setIsLoading(false)
    }
  }
  async function handleContinueToLawyer(data: FormUserData): Promise<void> {
    try {
      await userFormSchema.validate(data, { abortEarly: false })
      const res = await api.post('/lawyer/email', { email: data.email })
      if (res.data) {
        toast({
          status: 'error',
          description: 'Este e-mail já está cadastrado'
        })
      } else {
        setScreen('lawyer')
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({ status: 'error', description: formatErrorMessage(error) })
      }
    }
  }
  const handleSubmit: SubmitHandler = async data => {
    if (screen === 'user') await handleContinueToLawyer(data)
    else if (screen === 'lawyer') await handleSubmitLawyer(data)
  }

  return (
    <Grid
      w="full"
      h="100vh"
      gridTemplateColumns={isWideVersion ? '1fr 1fr' : '1fr'}
      gridTemplateRows={isWideVersion ? '1fr' : '185px 1fr'}
    >
      <Box pos="relative">
        <Img
          src="/lawy.webp"
          alt="advogados"
          w="full"
          h={isWideVersion ? '100%' : '185px'}
          objectFit="cover"
        />

        <Img
          src="/logo.svg"
          alt="uniadvogados"
          pos="absolute"
          w="100%"
          maxW={['200px', '300px', '400px']}
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        />
      </Box>
      <Center p="4" d="flex" flexDirection="column">
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          w="full"
          maxW="400"
          pt="5"
          pb="7"
          px="9"
          borderRadius="4"
          boxShadow="md"
          d="flex"
          flexDirection="column"
        >
          <Box d={screen === 'user' ? 'block' : 'none'}>
            <Heading fontSize="xl">Criar conta</Heading>
            <Divider my="4" />
            <Input
              name="name"
              label="Nome"
              placeholder="Informe seu nome"
              mb="4"
            />
            <Input
              name="lastName"
              label="Sobrenome"
              placeholder="Informe seu sobrenome"
              mb="3"
            />
            <Input
              name="email"
              label="E-mail"
              placeholder="Informe seu e-mail"
              mb="3"
            />
            <Button
              type="submit"
              colorScheme="yellow"
              w="full"
              h="48px"
              mt="5"
              fontSize="lg"
              isLoading={isLoading}
            >
              Continuar
            </Button>
          </Box>
          <Box d={screen === 'lawyer' ? 'block' : 'none'}>
            <Heading fontSize="xl">Criar conta</Heading>
            <Divider my="4" />
            <Input
              name="cpfCnpj"
              label="CPF/CNPJ"
              mask="cpfCnpj"
              info="Essa informação não ficará visível para os demais usuários"
              placeholder="Informe seu cpf ou cnpj"
              mb="4"
            />
            <Input
              name="whatsapp"
              label="Whatsapp"
              mask="phone"
              placeholder="Informe seu whatsapp"
              mb="3"
            />
            <Input
              name="oab"
              label="Registro OAB"
              mask="oab"
              placeholder="Informe seu registro OAB"
              mb="3"
            />
            <Input
              name="birthdate"
              label="Data de nascimento"
              type="date"
              info="Essa informação não ficará visível para os demais usuários"
              placeholder="Informe sua data de nascimento"
              mb="3"
            />
            <Input
              name="password"
              label="Senha"
              type="password"
              placeholder="Informe sua senha"
              mb="3"
            />
            <Input
              name="confirmPassword"
              label="Confirmar senha"
              type="password"
              placeholder="Confirme sua senha"
              mb="5"
            />
            <Checkbox name="terms">
              <>
                Declaro que li e concordo com os{' '}
                <Link as={ReactLink} to="terms" textDecoration="underline">
                  Termos e condições
                </Link>{' '}
                e{' '}
                <Link as={ReactLink} to="policy" textDecoration="underline">
                  Políticas de privacidade
                </Link>
                .
              </>
            </Checkbox>
            <Button
              type="submit"
              colorScheme="yellow"
              w="full"
              h="48px"
              mt="5"
              fontSize="lg"
              isLoading={isLoading}
            >
              Continuar
            </Button>
          </Box>
        </Form>
        <Link as={ReactLink} to="/signin" mt="4" d="inline-block">
          Já possui uma conta?{' '}
          <Text textDecoration="underline" d="inline">
            Entre
          </Text>
        </Link>
      </Center>
    </Grid>
  )
}
