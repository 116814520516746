import { useRef, useState } from 'react'
import { memo } from 'react'

import { useToast } from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import * as yup from 'yup'

import { Form } from '../../components/Form/Form'
import { Input } from '../../components/Form/Input'
import { FormContainer } from '../../components/FormContainer'
import { api } from '../../services/api'
import { formatErrorMessage } from '../../utils/formatErrorMessage'
import { formatYupError } from '../../utils/formatYupError'
import { passwordFormSchema } from './validationSchema'

type FormData = {
  password: string
  newPassword: string
  newPasswordConfirm: string
}

export function UpdatePasswordRaw(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const formRef = useRef<FormHandles>(null)
  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    setLoading(true)
    try {
      await passwordFormSchema.validate(data, { abortEarly: false })
      await api.put(`lawyer/settings/password`, data)
      toast({
        status: 'success',
        title: 'Sucesso!',
        description: 'Senha atualizada com sucesso!.'
      })
      reset()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({
          status: 'error',
          description: formatErrorMessage(error)
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormContainer
        headerText="Alterar Senha"
        buttonText="Atualizar"
        loading={loading}
      >
        <Input
          name="password"
          label="Senha Atual (obrigatório)"
          placeholder="Informe sua senha atual"
          type="password"
          mb="4"
        />
        <Input
          name="newPassword"
          label="Nova Senha (obrigatório)"
          placeholder="Informe sua nova senha"
          type="password"
          mb="4"
        />
        <Input
          name="newPasswordConfirm"
          label="Confirmar Senha (obrigatório)"
          placeholder="Confirme sua nova senha"
          type="password"
          mb="4"
        />
      </FormContainer>
    </Form>
  )
}

export const UpdatePassword = memo(UpdatePasswordRaw)
