import { useEffect, useRef, useState } from 'react'

import { FormControl, FormErrorMessage } from '@chakra-ui/form-control'
import { Center } from '@chakra-ui/layout'
import HCaptchaComponent from '@hcaptcha/react-hcaptcha'
import { useField } from '@unform/core'

type HCaptchaProps = {
  name: string
}

export const hCaptchaKey = process.env.REACT_APP_HCAPCHA_SITE_KEY

export function HCaptcha({ name }: HCaptchaProps): JSX.Element {
  const { fieldName, registerField, clearError, defaultValue, error } =
    useField(name)
  const [isVerified, setIsVerified] = useState(defaultValue || false)

  const hCaptchaRef = useRef<HCaptchaComponent>(null)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: hCaptchaRef.current,
      getValue: () => isVerified,
      setValue: (_, value) => setIsVerified(value),
      clearValue: (_, newValue) => {
        hCaptchaRef.current?.resetCaptcha()
        setIsVerified(newValue || false)
      }
    })
  }, [fieldName, registerField, isVerified])

  function handleLoad(): void {
    clearError()
    setTimeout(() => {
      const iframe: HTMLIFrameElement | null = document.querySelector(
        'iframe[title="Main content of the hCaptcha challenge"]'
      )
      const parent = iframe?.parentElement
      const captchaDiv = parent?.parentElement
      if (captchaDiv) {
        captchaDiv.style.transform = 'translateY(-25%)'
      }
    }, 500)
  }

  function handleVerify(): void {
    clearError()
    setIsVerified(true)
  }

  return (
    <FormControl isInvalid={!!error}>
      <Center>
        <HCaptchaComponent
          ref={hCaptchaRef}
          sitekey={hCaptchaKey}
          onLoad={handleLoad}
          onVerify={handleVerify}
        />
      </Center>
      {error && <FormErrorMessage ml="4">{error}</FormErrorMessage>}
    </FormControl>
  )
}
