import { IoMdInformationCircle } from 'react-icons/io'

import { Flex, Icon, Text } from '@chakra-ui/react'

import { Ellipse } from './Ellipse'
import { Tooltip } from './Tooltip'

export type StepProps = {
  id: number
  name: string
  done: boolean
}

type ProfileStepsProps = {
  step: StepProps
}

export function ProfileSteps({ step }: ProfileStepsProps): JSX.Element {
  const hasInfo = step.name === 'Tenha a sua Primeira Avaliação'
  const isDone = step.done
  const information =
    'Compartilhe a sua página com os seus clientes, quanto mais avaliações, melhor'

  return (
    <Flex w={'full'} alignItems={'center'}>
      <Ellipse isChecked={isDone} />
      <Flex
        borderBottom={'0.5px solid #E6E4E4'}
        py={'14px'}
        w={'full'}
        flexDir={'row'}
      >
        <Text
          textDecor={isDone ? 'line-through' : ''}
          color={isDone ? '#A7A7A7' : 'gray.700'}
          marginRight={2}
        >
          {step.name}
        </Text>
        {hasInfo && (
          <Tooltip label={information} placement="right">
            <button type="button" aria-label="Abrir informação extra">
              <Icon as={IoMdInformationCircle} />
            </button>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  )
}
