import { SimpleGrid } from '@chakra-ui/react'

import { PageTemplate } from '../../components/PageTemplate'
import { SelfUpdate } from './SelfUpdate'
import { UpdatePassword } from './UpdatePassword'
import { WayPayment } from './WayPayment'

export function Settings(): JSX.Element {
  return (
    <PageTemplate>
      <SimpleGrid
        columns={[1, 1, 2]}
        w="full"
        templateRows="450px 450px"
        spacingX="50px"
        spacingY="36px"
        pt="20px"
      >
        <SelfUpdate />
        <UpdatePassword />
        <WayPayment />
      </SimpleGrid>
    </PageTemplate>
  )
}
