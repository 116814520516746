import { Divider, Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react'

export function StepsSkeleton(): JSX.Element {
  return (
    <Flex
      direction={'column'}
      w={'full'}
      px="6"
      py="9"
      boxShadow="md"
      bg="white"
      borderRadius="6"
    >
      <Skeleton height="20px" w={200} mb={4} mt={2} />
      <Divider my="4" color={'#E6E4E4'} h={'0.5px'} />
      {[...Array(7)].map((_value, index) => (
        <Flex w={'full'} alignItems={'center'} key={index}>
          <SkeletonCircle size="6" mr={4} />
          <Flex
            borderBottom={'0.5px solid #E6E4E4'}
            py={'14px'}
            w={'full'}
            flexDir={'row'}
            alignItems={'center'}
          >
            <Skeleton height="15px" w={'full'} mb={2} mt={2} />
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}
