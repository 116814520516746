import * as yup from 'yup'

import { validateCpfCnpj, validateDate } from '../../utils/validations'

export const updateFormSchema = yup.object({
  email: yup
    .string()
    .email('E-mail Inválido.')
    .required('E-mail é obrigatório.'),
  cpfCnpj: yup
    .string()
    .test('Validation error', 'CPF/CNPJ é inválido.', validateCpfCnpj)

    .required('Nome é obrigatório!.'),
  birthdate: yup
    .string()
    .transform(value => {
      const [year, month, day] = value.split('-')
      return `${day}-${month}-${year}`
    })
    .test('validate date', 'Data de nascimento é obrigatória.', validateDate)
    .required('Data de nascimento é obrigatória.')
})

export const passwordFormSchema = yup.object({
  password: yup.string().required('Senha atual é obrigatória.'),
  newPassword: yup.string().required('Nova senha é obrigatória.'),
  newPasswordConfirm: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Senha precisa ser igual.')
    .required('Confirmação de senha é obrigatória.')
})

export const cardFormSchema = yup.object({
  number: yup
    .string()
    .transform(value => value.replace(/\D/g, ''))
    .min(12, 'Número de cartão incorreto.')
    .max(16, 'Número de cartão incorreto.')
    .required('Número de cartão obrigatório.'),
  name: yup.string().required('Nome é obrigatório.'),
  expiry: yup
    .string()
    .transform(value => value.replace(/\D/g, ''))
    .min(4, 'Data de expiração inválida.')
    .required('Validade é obrigatória.'),
  cvv: yup.string().required('CVV é obrigatório.')
})
